import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import { getParagraph } from "../utils/paragraphHelpers";

import { IContactProps } from "../pageHelpers/Contact/ContactProps";
import { ContactForm } from "../pageHelpers/Contact/ContactHelpers";
import { useAppDispatch } from "../hooks";
import { logout } from "../redux/slices/Member/member";
import { GATSBY_MOBILE_APP } from "../@types/Constants";
import ClientOnly from "../components/global/ClientOnly/ClientOnly";
import WebFramed from "../components/common/WebFramed/WebFramed";
import { Framedview_User_Agent } from "../@types/Constants";

const Contact = (props: IContactProps) => {
  const page = props.data.page;
  const [loading, setLoading] = React.useState(false);
  const paragraphs = page?.relationships?.paragraphs.map(getParagraph);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      let isLoading;
      setLoading((currentValue) => {
        isLoading = currentValue;
        return currentValue;
      });
      if (isLoading) {
        dispatch<any>(logout());
        setLoading(false);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  if (
    typeof window !== "undefined" &&
    navigator &&
    navigator.userAgent.indexOf(Framedview_User_Agent) > -1 &&
    GATSBY_MOBILE_APP
  ) {
    return (
      <ClientOnly>
        <WebFramed />
        <Layout>
          <SEO title={page?.title} description={page?.field_meta_description} />
          <ContactForm {...page} />
          {paragraphs}
        </Layout>
      </ClientOnly>
    );
  }
  return (
    <Layout>
      <SEO title={page?.title} description={page?.field_meta_description} />
      <ContactForm {...page} />
      {paragraphs}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactQuery {
    page: nodePage(path: { alias: { eq: "/v1/contact" } }) {
      id
      title
      field_meta_description
      body {
        value
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphMediaCta
        }
      }
    }
  }
`;
export default Contact;
