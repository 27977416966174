import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Select, { OptionProps } from "react-select";
import { useForm, Controller } from "react-hook-form";
import { graphql, useStaticQuery } from "gatsby";
import {
  container,
  title3,
  title1,
  subtitle,
  styledContainer,
  disclaimer,
  offset,
  aside,
  formGroup,
  submitButton,
  customIcon,
  modalLinks,
} from "./Contact.module.scss";
import {
  validateMaxLengthMessage,
  validateMinLengthMessage,
} from "../../utils/validations";
import { contactUs } from "../../services/support";
import HotelAutoComplete from "../../components/common/HotelAutoComplete/HotelAutoComplete";

import TermsOfServiceModal from "../../components/common/TermsOfServiceModal/TermsOfServiceModal";
import SuccessModal from "../../components/common/SuccessModal/SuccessModal";
import PrivacyModal from "../../components/common/PrivacyModal/PrivacyModal";
import { ColourOption } from "./ContactProps";
import { getToken } from "../../utils/auth0Client";
import { navigate } from "gatsby-link";
import "react-day-picker/lib/style.css";
import { InputDatePicker } from "./InputDatePicker";
import dateFnsFormat from "date-fns/format";
import { ContactUsFormGTMDataLayer } from "../../utils/datalayers";
import { isMobile } from "react-device-detect";

const options: readonly ColourOption[] = [
  { value: "YOUR HOTEL STAY", label: "YOUR HOTEL STAY", isDisabled: true },
  {
    value: "Request Folio",
    label: "Request Folio",
    apiUrl: "formFolioRequestContact",
  },
  {
    value: "Recent Stay",
    label: "Recent Stay",
    apiUrl: "formTravelpassContact",
  },
  {
    value: "SONESTA TRAVEL PASS",
    label: "SONESTA TRAVEL PASS",
    isDisabled: true,
  },
  {
    value: "Redeem Points",
    label: "Redeem Points",
    apiUrl: "formTravelpassRedeem",
  },
  {
    value: "Report a Missing Stay",
    label: "Report a Missing Stay",
    apiUrl: "formTravelpassMissing",
  },
  {
    value: "Account Login Help",
    label: "Account Login Help",
    apiUrl: "formTravelpassContact",
  },
  { value: "CONTACT A HOTEL", label: "CONTACT A HOTEL", isDisabled: true },
  {
    value: "Hotel General Manager",
    label: "Hotel General Manager",
    apiUrl: "formPropertyContact",
  },
  {
    value: "Sales",
    label: "Hotel Sales Manager",
    apiUrl: "formPropertyContact",
  },
  {
    value: "GENERAL QUESTIONS",
    label: "GENERAL QUESTIONS",
    isDisabled: true,
  },
  {
    value: "Corporate Inquiries",
    label: "Corporate Inquiries",
    apiUrl: "formCorporateContact",
  },
  {
    value: "Employee Compliance",
    label: "Employee Compliance",
    apiUrl: "formComplianceContact",
  },
];

const Option = (props: OptionProps<ColourOption>) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;
  return (
    <div
      ref={innerRef}
      style={getStyles("option", props)}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className
      )}
      {...innerProps}
    >
      {isDisabled && children !== "YOUR HOTEL STAY" && <hr />}
      {children}
    </div>
  );
};
const customStyles = (propErrors) => {
  return {
    control: (controlProps) => {
      controlProps[":hover"] = {
        borderColor: "#D3D3D3 !important",
      };
      const styles: any = {
        backgroundColor: "#FFFFFF",
        borderColor: "#D3D3D3",
        boxSizing: "border-box",
        height: "53px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        overflow: "hidden",
        ...controlProps,
      };
      if (propErrors?.helpOption?.message) {
        styles["borderColor"] = "#dc3545";
      }
      return styles;
    },
    indicatorSeparator: (indicatorProps) => {
      indicatorProps.backgroundColor = "white";
      return indicatorProps;
    },
    option: (
      base,
      { isDisabled, isSelected }: { isDisabled: boolean; isSelected: boolean }
    ) => ({
      ...base,
      borderColor: "black",
      boxSizing: "border-box",
      height: "100%",
      cursor: "pointer",
      padding: "12px 16px",
      fontSize: isDisabled ? "11px" : "16px",
      fontWeight: isDisabled ? "inherit" : "700",
      backgroundColor: isSelected ? "#F7F7F7" : "inherit",
      color: "#333333",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),
  };
};

export const Container = (props: { children: React.ReactNode }) => {
  return <div className={container}>{props.children}</div>;
};
export const Title1 = (props: {
  children: React.ReactNode;
  className?: string;
  id?: string;
}) => {
  return (
    <h1 className={`${title1} ${props.className}`} id={props.id}>
      {props.children}
    </h1>
  );
};
export const Title3 = (props: { children: React.ReactNode }) => {
  return <div className={title3}>{props.children}</div>;
};
export const Subtitle = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${subtitle} ${props.className}`}>{props.children}</div>
  );
};
export const StyledContainer = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${styledContainer} ${props.className}`}>
      {props.children}
    </div>
  );
};
export const Disclaimer = (props: { children: React.ReactNode }) => {
  return <div className={disclaimer}>{props.children}</div>;
};

export const ContactForm = (props: any) => {
  const member = useSelector((state: any) => state.member);
  const phone = useSelector((state: any) => {
    if (state.member.crmProfile) {
      return state.member.crmProfile.phone;
    }
    return "";
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const required = "This field is required.";
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const allHotelData = useStaticQuery(graphql`
    {
      allHotel {
        nodes {
          drupal_internal__id
          hotel_code
        }
      }
    }
  `);

  useEffect(() => {
    if (errors?.checkinDate && errors?.checkinDate?.message) {
      document
        .getElementsByClassName("contactFormDatePicker")[0]!
        .getElementsByTagName("input")[0]!.style!.borderColor = "red";
    }
    if (errors?.checkoutDate && errors?.checkoutDate?.message) {
      document
        .getElementsByClassName("contactFormDatePicker")[1]!
        .getElementsByTagName("input")[0]!.style!.borderColor = "red";
    }
  }, [errors]);

  const formData = watch();
  const handleSuccessClose = () => {
    setShowSuccess(false);
  };
  const handlePrivacyModalClose = () => {
    setShowPrivacyModal(false);
  };
  const handlePrivacyModalShow = () => {
    setShowPrivacyModal(true);
  };
  const handleTermsModalClose = () => {
    setShowTermsModal(false);
  };
  const handleTermsModalShow = () => {
    setShowTermsModal(true);
  };

  const onSubmitData = async (data: any, event: any) => {
    event.preventDefault();
    const helpOption = data.helpOption;

    if (
      !data.id &&
      helpOption &&
      ![
        "Account Login Help",
        "Corporate Inquiries",
        "Reporting Hotline",
      ].includes(helpOption.value)
    ) {
      setError("contactHotelCode", {
        message: "Please select hotel from suggested list.",
      });
      return;
    }

    const initialValues = getValues();
    const request = { ...initialValues, ...data };

    setErrorMessage("");
    setIsSubmitting(true);

    // if (request.contactHotelCode) {
    //     delete request.contactHotelCode; //as this is not needed in contact api
    // }
    let payload = {};
    if (
      data?.helpOption?.value === "Request Folio" ||
      data?.helpOption?.value === "Redeem Points" ||
      data?.helpOption?.value === "Report a Missing Stay"
    ) {
      payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        hotel: data?.id,
        department: data?.helpOption?.value,
        departmentLabel: data?.helpOption?.label,
        messageContent: data.details,
        hotelName: data.contactHotelCode,
        memberId: data.memberId,
        reservationID: data.reservationID,
        checkinDate: dateFnsFormat(data.checkinDate, "MM-dd-yyyy"),
        checkoutDate: dateFnsFormat(data.checkoutDate, "MM-dd-yyyy"),
      };
    } else {
      payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        hotel: data?.id,
        department: data?.helpOption?.value,
        departmentLabel: data?.helpOption?.label,
        messageContent: data.details,
        hotelName: data.contactHotelCode,
        memberId: data.memberId,
        reservationID: data.reservationID,
      };
    }
    const headers: { "Content-Type": string; Authorization?: string } = {
      "Content-Type": "application/json",
    };
    // if (data.helpOption.apiUrl === "formTravelpassRedeem") {
    //   const token = getToken();
    //   if (!token) {
    //     setIsSubmitting(false);
    //     alert("Please login");
    //     localStorage["contactUsRedirect"] = true;
    //     return navigate("/login");
    //   }
    //   headers["Authorization"] = `Bearer ${getToken()}`;
    // }
    try {
      const response = await fetch(`/.netlify/functions/${data.helpOption.apiUrl}`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (!result || result?.statusCode !== 200) {
        setErrorMessage(
          result.errorSummary ||
            `An error occurred while creating a help ticket.
          Please try again.`
        );
      } else {
        setShowSuccess(true);
        setValue("details", "");
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("phone", "");
        setValue("email", "");
        setValue("helpOption", "");
        setValue("hotelName", "");
        setValue("reservationID", "");
        setValue("memberID", "");
        setValue("checkinDate", "");
        setValue("checkoutDate", "");
        ContactUsFormGTMDataLayer(
          payload.departmentLabel,
          payload.hotelName,
          isMobile
        );
      }
      setIsSubmitting(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
    // const response = await contactUs(request);
  };

  useEffect(() => {
    setValue(
      "firstName",
      member.crmProfile.firstName || member.profile.firstName || ""
    );
    setValue(
      "lastName",
      member.crmProfile.lastName || member.profile.lastName || ""
    );
    setValue("email", member.crmProfile.email || member.profile.email || "");
    setValue("phone", phone || "");
    setValue(
      "helpOption",
      member.crmProfile.helpOption || member.profile.helpOption || ""
    );
    setValue(
      "hotelName",
      member.crmProfile.hotelName || member.profile.hotelName || ""
    );
    setValue(
      "reservationID",
      member.crmProfile.address1 || member.profile.address1 || ""
    );
    setValue("memberID", member.crmProfile.city || member.profile.city || "");
  }, [member, phone, setValue]);

  const handleHotelSelection = (data: any) => {
    const selectedHotel = allHotelData?.allHotel?.nodes?.find(
      (hotel) => hotel?.hotel_code === data?.hotel_code
    );
    setValue("contactHotelCode", data.name);
    setValue("id", selectedHotel?.drupal_internal__id);
  };

  const handleCheckInDate = (data: any) => {
    setValue("checkinDate", data);
    setError("checkinDate", { message: "" });
    if (errors?.checkinDate && errors?.checkinDate?.message == "") {
      if (
        document.getElementsByClassName("contactFormDatePicker").length > 0 &&
        document.getElementsByClassName("contactFormDatePicker")[0]
      ) {
        document
          .getElementsByClassName("contactFormDatePicker")[0]!
          .getElementsByTagName("input")[0]!.style!.borderColor = "#D3D3D3";
      }
    }
  };

  const handleCheckOutDate = (data: any) => {
    setValue("checkoutDate", data);
    setError("checkoutDate", { message: "" });
    if (errors?.checkoutDate && errors?.checkoutDate?.message == "") {
      if (
        document.getElementsByClassName("contactFormDatePicker").length > 0 &&
        document.getElementsByClassName("contactFormDatePicker")[1]
      ) {
        document
          .getElementsByClassName("contactFormDatePicker")[1]!
          .getElementsByTagName("input")[0]!.style!.borderColor = "#D3D3D3";
      }
    }
  };

  const values = getValues();
  return (
    <StyledContainer className="container-sm" fluid="sm">
      <Row>
        <Col lg={4} className={aside}>
          <Subtitle> CONTACT US </Subtitle>
          <Title1 id={"get-a-hold-of-us"}> Get a hold of us </Title1>
          <div
            dangerouslySetInnerHTML={{
              __html: props.body.value,
            }}
          />
        </Col>

        <Col lg={{ offset: 1 }} className={offset}>
          {errorMessage && <Alert variant={`danger`}> {errorMessage}</Alert>}
          <Form
            id="contact-us-form"
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmitData)}
          >
            <Form.Control type="hidden" name="id" ref={register} />
            <Row>
              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="firstName"
              >
                <Form.Label id="firstName-label">
                  First Name<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  as={
                    <Form.Control
                      isInvalid={errors?.firstName}
                      aria-required={true}
                      autoComplete="given-name"
                    />
                  }
                  type="text"
                  name="firstName"
                  control={control}
                  rules={{
                    required: required,
                    maxLength: {
                      value: 50,
                      message: validateMaxLengthMessage(50),
                    },
                  }}
                  defaultValue={values.firstName || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.firstName && errors?.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="lastName"
              >
                <Form.Label id="lastName-label">
                  Last Name<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  as={
                    <Form.Control
                      isInvalid={errors?.lastName}
                      aria-required={true}
                      autoComplete="family-name"
                    />
                  }
                  type="text"
                  name="lastName"
                  control={control}
                  rules={{
                    required: required,
                    maxLength: {
                      value: 50,
                      message: validateMaxLengthMessage(50),
                    },
                  }}
                  defaultValue={values.lastName || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.lastName && errors?.lastName.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="phone"
              >
                <Form.Label id="phoneNumber-label">
                  Phone Number<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  as={
                    <Form.Control
                      isInvalid={errors?.phone}
                      aria-required={true}
                      autoComplete="tel"
                    />
                  }
                  type="text"
                  name="phone"
                  control={control}
                  rules={{
                    pattern: {
                      //eslint-disable-next-line
                      value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\.0-9]*$/,
                      message: "A valid phone number is required.",
                    },
                    required: required,
                    minLength: {
                      value: 10,
                      message: validateMinLengthMessage(10),
                    },
                  }}
                  defaultValue={values.phone || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.phone && errors?.phone.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className={formGroup}
                lg={6}
                controlId="email"
              >
                <Form.Label id="emailAddress-label">
                  Email Address<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  as={
                    <Form.Control
                      isInvalid={errors?.email}
                      aria-required={true}
                      autoComplete="email"
                    />
                  }
                  name="email"
                  type="email"
                  control={control}
                  rules={{
                    required: required,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                      message: "A valid Email address is required.",
                    },
                    maxLength: {
                      value: 50,
                      message: validateMaxLengthMessage(50),
                    },
                  }}
                  defaultValue={values.email || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.email && errors?.email.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId={`helpOption`}
              >
                <Form.Label id="how-can-we-help-label">
                  How can we help?<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  as={
                    <Select
                      components={{ Option }}
                      options={options}
                      name="helpOption"
                      placeholder="Select a topic"
                      styles={customStyles(errors)}
                    />
                  }
                  name="helpOption"
                  type="select"
                  control={control}
                  rules={{
                    required: required,
                  }}
                  defaultValue={values.helpOption || ""}
                />
                {errors?.helpOption?.message && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: ".875em",
                      color: "#dc3545",
                    }}
                  >
                    {errors.helpOption.message}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="contactHotelCode"
              >
                <Form.Label id="hotelName-label">
                  {" "}
                  {![
                    "Account Login Help",
                    "Corporate Inquiries",
                    "Reporting Hotline",
                  ].includes(formData.helpOption?.value) ? (
                    <>
                      Hotel Name<span className="text-danger">*</span>
                    </>
                  ) : (
                    "Hotel Name (optional)"
                  )}{" "}
                </Form.Label>
                <Controller
                  as={
                    <HotelAutoComplete
                      onHotelSelection={handleHotelSelection}
                      id="contactHotelCode"
                      // inputRef={register()}
                      name="contactHotelCode"
                      errors={errors?.contactHotelCode}
                      suggestions={values.contactHotelCode}
                      customIconClass={customIcon}
                    />
                  }
                  name="contactHotelCode"
                  type="select"
                  control={control}
                  rules={
                    ![
                      "Account Login Help",
                      "Corporate Inquiries",
                      "Reporting Hotline",
                    ].includes(formData.helpOption?.value)
                      ? {
                          required: required,
                        }
                      : { required: false }
                  }
                  defaultValue={values.contactHotelCode || ""}
                />
              </Form.Group>
            </Row>
            {values.helpOption &&
            (values.helpOption.value === "Request Folio" ||
              values.helpOption.value === "Redeem Points" ||
              values.helpOption.value === "Report a Missing Stay") ? (
              <Row>
                <Form.Group
                  as={Col}
                  lg={6}
                  className={formGroup}
                  controlId="checkinDate"
                >
                  <Form.Label id="checkinDate-label">
                    Checkin Date<span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    as={
                      <InputDatePicker
                        value={values.checkinDate}
                        onHandleCheckInDate={handleCheckInDate}
                        checktype="checkIn"
                        id={`contactFormCheckin`}
                        inputValues={values}
                        error={errors?.checkinDate}
                      />
                    }
                    name="checkinDate"
                    type="date"
                    control={control}
                    rules={{
                      required: required,
                    }}
                    defaultValue={values.checkinDate || ""}
                  />
                  {errors.checkinDate
                    ? errors?.checkinDate?.message && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: ".875em",
                            color: "#dc3545",
                          }}
                        >
                          {errors.checkinDate.message}
                        </div>
                      )
                    : null}
                </Form.Group>

                <Form.Group
                  as={Col}
                  lg={6}
                  className={formGroup}
                  controlId="checkoutDate"
                >
                  <Form.Label id="checkoutDate-label">
                    Checkout Date<span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    as={
                      <InputDatePicker
                        value={new Date()}
                        onHandleCheckOutDate={handleCheckOutDate}
                        checktype="checkOut"
                        id={`contactFormCheckout`}
                        inputValues={values}
                        error={errors?.checkoutDate}
                      />
                    }
                    name="checkoutDate"
                    type="date"
                    control={control}
                    rules={{
                      required: required,
                    }}
                    defaultValue={values.checkoutDate || ""}
                  />
                  {errors?.checkoutDate?.message && (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: ".875em",
                        color: "#dc3545",
                      }}
                    >
                      {errors.checkoutDate.message}
                    </div>
                  )}
                </Form.Group>
              </Row>
            ) : null}

            <Row>
              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="reservationID"
                aria-required={
                  values.helpOption &&
                  values.helpOption.value === "Request Folio"
                    ? true
                    : false
                }
              >
                <Form.Label id="reservationID-label">
                  Reservation ID{" "}
                  {values.helpOption &&
                  values.helpOption.value === "Request Folio" ? (
                    <span className="text-danger">*</span>
                  ) : (
                    "(optional)"
                  )}
                </Form.Label>
                <Controller
                  as={<Form.Control isInvalid={errors?.reservationID} />}
                  type="text"
                  name="reservationID"
                  control={control}
                  rules={
                    values.helpOption &&
                    values.helpOption.value === "Request Folio"
                      ? {
                          required: required,
                          maxLength: {
                            value: 20,
                            message: validateMaxLengthMessage(20),
                          },
                        }
                      : {
                          maxLength: {
                            value: 20,
                            message: validateMaxLengthMessage(20),
                          },
                        }
                  }
                  defaultValue={values.reservationID || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.confirmation && errors?.confirmation.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                lg={6}
                className={formGroup}
                controlId="memberId"
              >
                <Form.Label id="memberId-label">
                  Member ID (optional)
                </Form.Label>
                <Controller
                  as={<Form.Control isInvalid={errors?.confirmation} />}
                  type="text"
                  name="memberId"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: validateMaxLengthMessage(20),
                    },
                  }}
                  defaultValue={values.memberId || ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="Alert"
                  aria-live="Assertive"
                >
                  {errors?.confirmation && errors?.confirmation.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group className={formGroup} controlId="details">
              <Form.Label id="comments-label">
                Comments<span className="text-danger">*</span>
              </Form.Label>
              <Controller
                as={
                  <Form.Control
                    as="textarea"
                    rows={5}
                    isInvalid={errors?.details}
                    aria-required={true}
                  />
                }
                name="details"
                control={control}
                rules={{
                  required: required,
                }}
                defaultValue={values.details || ""}
                placeholder="Enter comments here"
              />
              <Form.Control.Feedback
                type="invalid"
                role="Alert"
                aria-live="Assertive"
              >
                {errors?.details && errors?.details.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              id="submit-contact-us"
              disabled={isSubmitting}
              className={" text-uppercase w-100 mt-2 " + submitButton}
            >
              {isSubmitting ? `Please wait...` : `Contact Us`}
            </Button>

            <Disclaimer className="mt-30 d-block">
              {/* By submitting this form I certify that I have read, understand, and accept the <Button variant="link" className="termsOfService" onClick={handleTermsModalShow} tabIndex={0}>Terms of Service</Button>&nbsp;and&nbsp;<Button variant="link" className="termsOfService" onClick={handlePrivacyModalShow} tabIndex={0}>Privacy Statement</Button>.   <br></br> <br></br> */}
              We respond to most requests within 48 hours of submission.
            </Disclaimer>
          </Form>
        </Col>
      </Row>

      <PrivacyModal show={showPrivacyModal} onHide={handlePrivacyModalClose} />

      <TermsOfServiceModal
        show={showTermsModal}
        onHide={handleTermsModalClose}
      />

      <SuccessModal
        show={showSuccess}
        onHide={handleSuccessClose}
        id={"contact-us-email-success"}
      >
        <Subtitle className="text-center mb-1">CONFIRMATION</Subtitle>
        <Title3 className="m-3">Thank you for contacting us.</Title3>
        <p className="p-2">
          Our team has received your message and will respond within 48 hours.
        </p>
        <p className="m-0">
          <i className="font-weight-bold">
            You will receive a confirmation email shortly.
          </i>
        </p>
      </SuccessModal>
    </StyledContainer>
  );
};
