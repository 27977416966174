import React from "react";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";
import { checkinDate } from "./Contact.module.scss";
export const searchBarDateFormat = (date: number | Date, _format: any, locale: any) => {
  return dateFnsFormat(date, "MM-dd-yyyy", { locale });
};
const parseDate = (str: string, format: any, locale: any = 'en') => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};
const displayDateFormat = 'MM dd, yyyy';//"MMM dd, YYYY";
let dayPickerInputRef: any = null;

const CustomOverlay = ({ selectedDay, classNames, children, ...props }: any) => {
  return (
    <div
      className={`${classNames.overlayWrapper} `}
      {...props}
    >
      <div className={`${classNames.overlay} `}>
        {children}
      </div>
    </div>
  );
};


export const InputDatePicker = ({value, checktype, onHandleCheckInDate, onHandleCheckOutDate, id,inputValues} :any) => {

  const setDayPickerTop = () => {
    const datesDiv = document.getElementById("checkInOutDate");
    if (
      datesDiv &&
      window.innerHeight - datesDiv.getBoundingClientRect().y < 350
    ) {
      if (document.getElementsByClassName("DayPickerInput-Overlay").length) {
        const element: any = document.getElementsByClassName(
          "DayPickerInput-Overlay"
        )[0];
        element.style.bottom = "70px";
      }
    } else {
      if (document.getElementsByClassName("DayPickerInput-Overlay").length) {
        const element: any = document.getElementsByClassName(
          "DayPickerInput-Overlay"
        )[0];
        element.style.bottom = "auto";
      }
    }
  };

  const handleCheckInShow = () => {
    setDayPickerTop();
    dayPickerInputRef.input.classList.add('selected');
  };

  let disableDate = null;
  if(checktype == "checkOut" &&inputValues?.checkinDate !== ''){
      disableDate = inputValues?.checkinDate;
  }
  else{
    disableDate = null;
  }
 
  return (
    <div className={`${checkinDate} col-lg-12 contactFormDatePicker`} id={id}>
      <DayPickerInput 
      value={value}
      ref={ref => (dayPickerInputRef = ref)}
      format={displayDateFormat}
      formatDate={searchBarDateFormat}
      parseDate={parseDate}
      placeholder="MM/DD/YYYY"
      overlayComponent={CustomOverlay}
      keepFocus={false}
      dayPickerProps={{
        numberOfMonths: 1,
        selectedDays:[value],
        disabledDays: { before: disableDate},
      }}
      onDayPickerShow={handleCheckInShow}
      onDayChange={checktype == "checkOut" ? onHandleCheckOutDate : onHandleCheckInDate}
      />
    </div>
  );
};
